<script>
import SvgIcon from '@jamescoyle/vue-icon'
import { mdiAccount } from '@mdi/js'
import { mapState, mapActions } from 'vuex'
require('./assets/styles/main.css')
export default {
  el: '#app',
  name: 'VizionConnect',
  components: {
    SvgIcon
  },
  data () {
    return {
      icon: mdiAccount,
      isDropdownVisible: false
    }
  },
  created () {

  },
  mounted () {
    this.updateSiteHostName('location.vizion-connect.com')
    this.updateSiteFleetName('Flotte principale')
  },
  computed: {
    ...mapState(['breadcrumbs',
      'routerLinkHomeIsActive',
      'routerLinkTrouverUneAgenceIsActive',
      'routerLinkProfessionnelIsActive',
      'routerLinkFaqIsActive',
      'routerLinkMeConnecterIsActive',
      'isReservation',
      'isChoix',
      'isOptions',
      'isModePaiement',
      'isIncription',
      'isFinalisation'])
  },
  methods: {
    ...mapActions(['updateSiteHostName', 'updateSiteFleetName', 'updateSiteFleetListeVehicules']),
    onClickRouterLinkHome () {
      this.$store.commit('UPDATE_ROUTER_LINK_HOME', true)
      this.$store.commit('UPDATE_ROUTER_LINK_TROUVER_AGENCE', false)
      this.$store.commit('UPDATE_ROUTER_LINK_PROFESSIONNEL', false)
      this.$store.commit('UPDATE_ROUTER_LINK_FAQ', false)
      this.$store.commit('UPDATE_ROUTER_LINK_ME_CONNECTER', false)
    },
    onClickRouterLinkTrouverUneAgence () {
      this.$store.commit('UPDATE_ROUTER_LINK_HOME', false)
      this.$store.commit('UPDATE_ROUTER_LINK_TROUVER_AGENCE', true)
      this.$store.commit('UPDATE_ROUTER_LINK_PROFESSIONNEL', false)
      this.$store.commit('UPDATE_ROUTER_LINK_FAQ', false)
      this.$store.commit('UPDATE_ROUTER_LINK_ME_CONNECTER', false)
    },
    onClickRouterLinkProfessionnel () {
      this.$store.commit('UPDATE_ROUTER_LINK_HOME', false)
      this.$store.commit('UPDATE_ROUTER_LINK_TROUVER_AGENCE', false)
      this.$store.commit('UPDATE_ROUTER_LINK_PROFESSIONNEL', true)
      this.$store.commit('UPDATE_ROUTER_LINK_FAQ', false)
      this.$store.commit('UPDATE_ROUTER_LINK_ME_CONNECTER', false)
    },
    onClickRouterLinkFaq () {
      this.$store.commit('UPDATE_ROUTER_LINK_HOME', false)
      this.$store.commit('UPDATE_ROUTER_LINK_TROUVER_AGENCE', false)
      this.$store.commit('UPDATE_ROUTER_LINK_PROFESSIONNEL', false)
      this.$store.commit('UPDATE_ROUTER_LINK_FAQ', true)
      this.$store.commit('UPDATE_ROUTER_LINK_ME_CONNECTER', false)
    },
    onClickRouterLinkMeConnecter () {
      this.$store.commit('UPDATE_ROUTER_LINK_HOME', false)
      this.$store.commit('UPDATE_ROUTER_LINK_TROUVER_AGENCE', false)
      this.$store.commit('UPDATE_ROUTER_LINK_PROFESSIONNEL', false)
      this.$store.commit('UPDATE_ROUTER_LINK_FAQ', false)
      this.$store.commit('UPDATE_ROUTER_LINK_ME_CONNECTER', true)
    },
    onRefresStateSubNav () {

    },
    toggleDropdownMenu () {
      this.isDropdownVisible = !this.isDropdownVisible
    }
  }
}
</script>
<template>
  <div id="menuHautPrincipal">
    <div id="nav">
      <img class="imgLogo" alt="Vizion Connect" src="./assets/chopart.png" />
      <button id="menuToggle" @click="toggleDropdownMenu">Menu</button>
      <span v-if="routerLinkHomeIsActive" class="disabled">Faire une réser<span class="currentRouterLink">vation</span></span>
      <router-link v-else to="/" @click="onClickRouterLinkHome" class="routerLink">Faire une réservation</router-link>
      <span v-if="routerLinkTrouverUneAgenceIsActive" class="disabled">Trouver une <span class="currentRouterLink">agence</span></span>
      <router-link v-else to="/trouver-une-agence" @click="onClickRouterLinkTrouverUneAgence" class="routerLink">Trouver une agence</router-link>
      <span v-if="routerLinkProfessionnelIsActive" class="disabled">Profess<span class="currentRouterLink">ionnel</span></span>
      <router-link v-else to="/professionnel" @click="onClickRouterLinkProfessionnel" class="routerLink">Professionnel</router-link>
      <span v-if="routerLinkFaqIsActive" class="disabled currentRouterLink">FAQ</span>
      <router-link v-else to="/faq" @click="onClickRouterLinkFaq" class="routerLink">FAQ</router-link>
    </div>
  <div id="dropdownMenu" v-if="isDropdownVisible">
    <span v-if="routerLinkHomeIsActive" class="disabledResp">Faire une réservation</span>
    <router-link v-else to="/" @click="onClickRouterLinkHome" class="routerLink">Faire une réservation</router-link><br><br>
    <span v-if="routerLinkTrouverUneAgenceIsActive" class="disabledResp">Trouver une agence</span>
    <router-link v-else to="/trouver-une-agence" @click="onClickRouterLinkTrouverUneAgence" class="routerLink">Trouver une agence</router-link><br><br>
    <span v-if="routerLinkProfessionnelIsActive" class="disabledResp">Professionnel</span>
    <router-link v-else to="/professionnel" @click="onClickRouterLinkProfessionnel" class="routerLink">Professionnel</router-link><br><br>
    <span v-if="routerLinkFaqIsActive" class="disabledResp currentRouterLink">FAQ</span>
    <router-link v-else to="/faq" @click="onClickRouterLinkFaq" class="routerLink">FAQ</router-link>
  </div>
  </div>
  <!--
  <div id="connection">
    <p><svg-icon type="mdi" :path="icon" :size="40"></svg-icon></p>
    <p>
      <span v-if="routerLinkMeConnecterIsActive" class="disabledConnection">Me connecter à mon <span class="currentRouterLink">compte</span></span>
      <router-link v-else to="/me-connecter-a-mon-compte" @click="onClickRouterLinkMeConnecter">Me connecter à mon compte</router-link>
    </p>
  </div>
  -->
  <div>
    <v-divider :thickness="10" color="#68AF4D" class="border-opacity-100"></v-divider>
  </div>
  <div id="breadcrumbs">
    <span v-if="!isReservation" class="floatLeft margin--left--20px disabledGris" >Réservation</span>
    <router-link v-else to="/" class="floatLeft margin--left--20px" >Réservation</router-link>
    <span v-if="!isChoix && isReservation" class="floatLeft margin--left--20px disabledGris" >Choix du véhicule</span>
    <router-link v-if="isChoix" to="/listeVehicules" class="floatLeft margin--left--20px" >Choix du véhicule</router-link>
    <span v-if="!isOptions && isChoix" class="floatLeft margin--left--20px disabledGris" >Options</span>
    <router-link v-if="isOptions" to="/detailsEtOptions" class="floatLeft margin--left--20px" >Options</router-link>
    <span v-if="!isModePaiement && isOptions" class="floatLeft margin--left--20px disabledGris" >Mode de paiement</span>
    <router-link v-if="isModePaiement" to="/moyensPaiements" class="floatLeft margin--left--20px" >Mode de paiement</router-link>
    <span v-if="!isIncription && isModePaiement" class="floatLeft margin--left--20px disabledGris" >Inscription</span>
    <router-link v-if="isIncription" to="/connectionInscription" class="floatLeft margin--left--20px" >Inscription</router-link>
    <span v-if="!isFinalisation" style="display:none;" class="floatLeft margin--left--20px disabledGris" >Finalisation</span>
    <router-link v-else to="/finalisation" class="floatLeft margin--left--20px" >Finalisation</router-link>
    <div class="clearLeft"></div>
  </div>
  <router-view/>
</template>
