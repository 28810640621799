<script>
import SvgIcon from '@jamescoyle/vue-icon'
import { mdiArrowRightThin } from '@mdi/js'
import { mapState, mapActions } from 'vuex'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import axios from 'axios'
// const moment = require('moment')
const NB_HEURES_AVANT_RESERVATION = Number(24 * 60 * 60 * 1000)
const UNE_HEURE_AVANT_RETOUR = Number(60 * 60 * 1000)
const DATE_COURANTE = new Date()
const DATE_HEURE_COURANTE_FORMAT = DATE_COURANTE.toLocaleDateString() + ' ' + DATE_COURANTE.toLocaleTimeString()
const SECONDE_DATE_HEURE_COURANTE = Number(DATE_COURANTE.toLocaleTimeString().split(':')[2]) * 1000
const PUBLIC_URL = 'https://app.vizion-connect.com'
export default {
  name: 'ReservationView',
  components: {
    SvgIcon,
    VueDatePicker
  },
  data () {
    return {
      dateCourante: new Date(),
      path: mdiArrowRightThin,
      dateDepart: null,
      dateRetour: null,
      placeHolderDateDepartRetour: 'jj-mm-aaaa hh:mm',
      messageErreur: '',
      isActiveInfosErreurs: false,
      selectVille: null,
      pickerDateDateDepart: null,
      pickerDateDateRetour: null,
      valueFormat: 'dd-MM-yyyy HH:mm',
      dateFormat: 'dd-MM-yyyy HH:mm',
      localeFr: 'fr',
      radioGroupTypeLocation: 'particulier',
      checkBoxAge: false,
      siteEnDurTmp: {
        _id: 'wmrdGTiAQN4UxxFgWGzf4',
        owner: {
          type: 'organization',
          id: '3sI4vCyup',
          name: 'CHOPARD'
        },
        host: 'chopard.vizion-connect.com',
        fleet: {
          id: 'root',
          name: 'Flotte principale'
        }
      },
    }
  },
  computed: {
    ...mapState(['breadcrumbs',
      'lieuDepartRetour',
      'dateHeureDepart',
      'dateHeureRetour',
      'typeLocation',
      'isAgePlus25ans',
      'isInfosErreurs',
      'isReservation',
      'owner',
      'listeAgences',
      'objSite'])
  },
  created () {
    this._FnGetSites()
  },
  mounted () {
    this.updateLieuDepartRetour(this.lieuDepartRetour)
    this.updateDateHeureDepart(this.dateHeureDepart)
    this.updateDateHeureRetour(this.dateHeureRetour)
    this.updateTypeLocation(this.typeLocation)
    this.updateIsAgePlus25ans(this.isAgePlus25ans)
    this.updateIsInfosErreurs(this.isActiveInfosErreurs)
    this.isActiveInfosErreurs = this.isInfosErreurs
    this._FnRefreshInfosSaisies()
  },
  methods: {
    ...mapActions(['updateSiteHostName',
      'updateSiteOwner',
      'updateSiteFleetName',
      'updateLieuDepartRetour',
      'updateDateHeureDepart',
      'updateDateHeureRetour',
      'updateTypeLocation',
      'updateIsAgePlus25ans',
      'updateIsInfosErreurs',
      'updateIsReservation',
      'updateIsChoix',
      'updateIsOptions',
      'updateIsModePaiement',
      'updateIsInscription',
      'updateIsFinalisation',
      'updateListeAgences',
      'updateSiteFleetListeVehicules',
      'updateObjSite']),
    async _FnListeAgences () {
      await this.axios.get('/car-sharing/api/v1/agences').then(response => {
        const agences = response.data.results
        this.updateListeAgences(agences)
      })
    },
    _FnGetSites () {
      this._FnUpdateSiteHostFleetOwner()
      this._FnListeAgences()

    },
    _FnUpdateSiteHostFleetOwner () {
      this.objSite = this.siteEnDurTmp
      this.updateObjSite(this.siteEnDurTmp)
      this.updateSiteHostName(this.objSite.host)
      this.updateSiteFleetName(this.objSite.fleet.name)
      this.updateSiteOwner(this.objSite.owner)
    },
    async _FncGetListeVehicles () {
      const params = {
        'start-date': this.dateHeureDepart,
        'end-date': this.dateHeureRetour
      }
      this.axios.get('/car-sharing/api/v1/vehicles', { params }).then(response => {
        const vehicules = response.data.results
        if(vehicules != null) {
          this.updateSiteFleetListeVehicules(vehicules)
        }
      })     
    },
    rechercherVehicules () {
      if (this.lieuDepartRetour != null && this.dateHeureDepart != null && this.dateHeureRetour != null && !this.isActiveInfosErreurs) {
        this.updateIsReservation(true)
        this._FncGetListeVehicles()
        this.$router.push({ name: 'listeVehicules' })
      } else if (this.lieuDepartRetour == null) {
        this.messageErreur = 'Sélectionnez une ville de départ'
        this.isActiveInfosErreurs = true
        this.updateIsInfosErreurs(this.isActiveInfosErreurs)
        this.isActiveInfosErreurs = this.isInfosErreurs
      } else if (this.dateHeureDepart == null) {
        this.messageErreur = 'Sélectionnez une date de départ'
        this.isActiveInfosErreurs = true
        this.updateIsInfosErreurs(this.isActiveInfosErreurs)
        this.isActiveInfosErreurs = this.isInfosErreurs
      } else if (this.dateHeureRetour == null) {
        this.messageErreur = 'Sélectionnez une date de retour'
        this.isActiveInfosErreurs = true
        this.updateIsInfosErreurs(this.isActiveInfosErreurs)
        this.isActiveInfosErreurs = this.isInfosErreurs
      } else {
        this.isActiveInfosErreurs = true
        this.updateIsInfosErreurs(this.isActiveInfosErreurs)
        this.isActiveInfosErreurs = this.isInfosErreurs
      }
    },
    setLieuDepartRetour (value) {
      this.updateLieuDepartRetour(value.ville)
      if (this.dateHeureDepart != null) {
        this.onChangeDateHeureDepart(new Date(this.dateHeureDepart))
      }
      if (this.dateHeureRetour != null) {
        this.onChangeDateHeureRetour(new Date(this.dateHeureRetour))
      }
    },
    setTypeLocation (event) {
      this.updateTypeLocation(event.target.value)
    },
    onChangeCheckBoxAge (event) {
      this.updateIsAgePlus25ans(event.target.checked)
    },
    onChangeDateHeureDepart (modelData) {
      if (modelData) {
        const day = this._FnPadLeftDate(modelData.getDate())
        const month = this._FnPadLeftDate(modelData.getMonth() + 1)
        const year = this._FnPadLeftDate(modelData.getFullYear())
        const heures = this._FnPadLeftDate(modelData.getHours())
        const minutes = this._FnPadLeftDate(modelData.getMinutes())
        this.updateDateHeureDepart(`${day}-${month}-${year} ${heures}:${minutes}`)
        var tmp = this.dateHeureDepart
        var pDateHeureDepart = tmp.replace(/(..)-(..)-(....) (..):(..)/, '$3-$2-$1 $4:$5')
        var tmpDateCourante = DATE_HEURE_COURANTE_FORMAT
        var pDateHeureCourante = tmpDateCourante.replace(/(..)\/(..)\/(....) (..):(..)/, '$3-$2-$1 $4:$5')
        var pDateHeureDepartMillisSecondes = new Date(pDateHeureDepart).getTime()
        var pDateHeureCouranteMillisSecondes = new Date(pDateHeureCourante).getTime()
        if (Number((pDateHeureDepartMillisSecondes + SECONDE_DATE_HEURE_COURANTE) - pDateHeureCouranteMillisSecondes) < NB_HEURES_AVANT_RESERVATION) {
          this.messageErreur = 'La date de départ doit être au moins 24 heures à l\'avance par rapport à la date courante.'
          this.isActiveInfosErreurs = true
          this.updateIsInfosErreurs(this.isActiveInfosErreurs)
          this.isActiveInfosErreurs = this.isInfosErreurs
        } else if (this.dateHeureRetour != null) {
          var tmpDateRetour = this.dateHeureRetour
          var pDateRetour = tmpDateRetour.replace(/(..)-(..)-(....) (..):(..)/, '$3-$2-$1 $4:$5')
          var pDateRetourMillisSecondes = new Date(pDateRetour).getTime()
          if (Number(pDateRetourMillisSecondes - pDateHeureDepartMillisSecondes) < UNE_HEURE_AVANT_RETOUR - SECONDE_DATE_HEURE_COURANTE) {
            this.messageErreur = 'La date de retour doit être au moins d\'une heure à l\'avance par rapport à celle de départ.'
            this.isActiveInfosErreurs = true
            this.updateIsInfosErreurs(this.isActiveInfosErreurs)
            this.isActiveInfosErreurs = this.isInfosErreurs
          }
          if (Number(pDateRetourMillisSecondes) < Number(pDateHeureDepartMillisSecondes)) {
            this.messageErreur = 'La date de retour doit être plus grande que celle de départ.'
            this.isActiveInfosErreurs = true
            this.updateIsInfosErreurs(this.isActiveInfosErreurs)
            this.isActiveInfosErreurs = this.isInfosErreurs
          }
        } else {
          this.isActiveInfosErreurs = false
          this.updateIsInfosErreurs(this.isActiveInfosErreurs)
          this.isActiveInfosErreurs = this.isInfosErreurs
        }
      }
    },
    onChangeDateHeureRetour (modelData) {
      this.isActiveInfosErreurs = false
      if (modelData) {
        const day = this._FnPadLeftDate(modelData.getDate())
        const month = this._FnPadLeftDate(modelData.getMonth() + 1)
        const year = this._FnPadLeftDate(modelData.getFullYear())
        const heures = this._FnPadLeftDate(modelData.getHours())
        const minutes = this._FnPadLeftDate(modelData.getMinutes())
        this.updateDateHeureRetour(`${day}-${month}-${year} ${heures}:${minutes}`)
        var tmp = this.dateHeureRetour
        var pDateHeureRetour = tmp.replace(/(..)-(..)-(....) (..):(..)/, '$3-$2-$1 $4:$5')
        var tmpDateCourante = DATE_HEURE_COURANTE_FORMAT
        var pDateHeureCourante = tmpDateCourante.replace(/(..)\/(..)\/(....) (..):(..)/, '$3-$2-$1 $4:$5')
        var pDateRetourMillisSecondes = new Date(pDateHeureRetour).getTime()
        var pDateHeureCouranteMillisSecondes = new Date(pDateHeureCourante).getTime()
        if (Number((pDateRetourMillisSecondes + SECONDE_DATE_HEURE_COURANTE) - pDateHeureCouranteMillisSecondes) < UNE_HEURE_AVANT_RETOUR) {
          this.messageErreur = 'La date de retour doit être au moins d\'une heure à l\'avance par rapport à la date courante.'
          this.isActiveInfosErreurs = true
          this.updateIsInfosErreurs(this.isActiveInfosErreurs)
          this.isActiveInfosErreurs = this.isInfosErreurs
        } else if (this.dateHeureDepart != null) {
          var tmpDateHeureDepart = this.dateHeureDepart
          var pDateHeureDepart = tmpDateHeureDepart.replace(/(..)-(..)-(....) (..):(..)/, '$3-$2-$1 $4:$5')
          var pDateHeureDepartMillisSecondes = new Date(pDateHeureDepart).getTime()
          if (Number(pDateRetourMillisSecondes - pDateHeureDepartMillisSecondes) < UNE_HEURE_AVANT_RETOUR - SECONDE_DATE_HEURE_COURANTE) {
            this.messageErreur = 'La date de retour doit être au moins d\'une heure à l\'avance par rapport à celle de départ.'
            this.isActiveInfosErreurs = true
            this.updateIsInfosErreurs(this.isActiveInfosErreurs)
            this.isActiveInfosErreurs = this.isInfosErreurs
          }
          if (Number(pDateRetourMillisSecondes) < Number(pDateHeureDepartMillisSecondes)) {
            this.messageErreur = 'La date de retour doit être plus grande que celle de départ.'
            this.isActiveInfosErreurs = true
            this.updateIsInfosErreurs(this.isActiveInfosErreurs)
            this.isActiveInfosErreurs = this.isInfosErreurs
          }
        } else {
          this.isActiveInfosErreurs = false
          this.updateIsInfosErreurs(this.isActiveInfosErreurs)
          this.isActiveInfosErreurs = this.isInfosErreurs
        }
      }
    },
    _FnPadLeftDate (value) {
      if (Number(value) < 10) {
        return '0' + value
      }
      return value
    },
    _FnRefreshInfosSaisies () {
      if (this.dateHeureDepart != null) {
        var tmp = this.dateHeureDepart
        var permuteDDtoMM = tmp.replace(/(..)-(..)-(....) (..):(..)/, '$2-$1-$3 $4:$5')
        this.pickerDateDateDepart = permuteDDtoMM
        this.onChangeDateHeureDepart(new Date(this.pickerDateDateDepart))
      } else {
        this.pickerDateDateDepart = null
      }
      if (this.dateHeureRetour != null) {
        var tmpRet = this.dateHeureRetour
        var permuteDDtoMMRet = tmpRet.replace(/(..)-(..)-(....) (..):(..)/, '$2-$1-$3 $4:$5')
        this.pickerDateDateRetour = permuteDDtoMMRet
        this.onChangeDateHeureRetour(new Date(this.pickerDateDateRetour))
      } else {
        this.pickerDateDateRetour = null
      }
      if (this.lieuDepartRetour != null) {
        this.selectVille = this.lieuDepartRetour
      } else {
        this.selectVille = null
      }
      if (this.typeLocation != null) {
        this.radioGroupTypeLocation = this.typeLocation
      } else {
        this.radioGroupTypeLocation = 'particulier'
      }
      if (this.isAgePlus25ans) {
        this.checkBoxAge = true
      } else {
        this.checkBoxAge = false
      }
    }
  }
}
</script>
<template>
  <div class="faire--reservation">
    <div id="conteneurCadreReservation">
      <div class="cadre--reservation">
        <p class="reservation--label">Lieu de départ et de retour *</p>
        <v-autocomplete
          v-model="selectVille"
          append-inner-icon="mdi-magnify"
          clearable
          placeholder="Sélectionner un aéroport, une ville, une gare ..."
          :items="listeAgences"
          item-title="ville"
          item-value="ville"
          variant="filled"
          chips
          closable-chips
          return-object
          @update:modelValue="setLieuDepartRetour">
        </v-autocomplete>
        <div style="float:left" class="largeur47pourcent">
          <p class="reservation--label">Date et heure de départ *</p>
          <p>
            <VueDatePicker time-picker-inline text-input input-class-name="dp-custom-input" v-model="pickerDateDateDepart" :locale="localeFr" :placeholder="placeHolderDateDepartRetour" :format="dateFormat" :value-format="valueFormat" @update:model-value="onChangeDateHeureDepart"></VueDatePicker>
          </p>
        </div>
        <div style="padding-left:20px" class="largeur47pourcent floatRightDateRes">
          <p class="reservation--label">Date et heure de retour *</p>
          <p>
            <VueDatePicker time-picker-inline text-input input-class-name="dp-custom-input" v-model="pickerDateDateRetour" :locale="localeFr" :placeholder="placeHolderDateDepartRetour" :format="dateFormat" :value-format="valueFormat" @update:model-value="onChangeDateHeureRetour"></VueDatePicker>
          </p>
        </div>
        <div class="blocType--de--location--conducteur floatLeft">
          <p class="reservation--label">Type de location</p>
          <p class="radioGroup" style="height:25px">
            <v-radio-group
              inline
              v-model="radioGroupTypeLocation"
              @change="setTypeLocation">
              <v-radio label="Particulier" value="Particulier" @change="setTypeLocation"></v-radio>
              <v-radio label="Professionnel" value="Professionnel" @change="setTypeLocation"></v-radio>
            </v-radio-group>
          </p>
          <p>
            <v-checkbox class="checkBox"
              label="Conducteur âgé plus de 25 ans"
              v-model="checkBoxAge"
              :value=true
              @change.prevent="onChangeCheckBoxAge">
            </v-checkbox>
          </p>
        </div>
        <!-- affich age d'info des erreurs possibles -->
        <div class="floatRight infosErreurs">
          <p>
            <span v-if="this.isActiveInfosErreurs" class="infosErreursVisible floatLeft">{{ messageErreur }}</span>
            <span v-else class="infosErreursNone floatLeft"></span>
          </p>
          <div class="clearBoth"></div>
        </div>
        <div class="clearLeft"></div>
        <div style="text-align:center">
          <v-btn class="text-none text-subtitle-1 bt--rechercher"
            color="#68AF4D"
            rounded="xl"
            width="250"
            @click.prevent="rechercherVehicules">
              RECHERCHER
          </v-btn>
        </div>
      </div>
    </div>
    <!-- fin #conteneurCadreReservation -->
    <div id="contenuInfosLocations">
      <!-- Locations en agence -->
      <div class="largeur47pourcentInfos floatLeft">
        <img class="imgInfosLocation" src="../assets/locations-en-agence.png" />
        <div class="padding30px">
          <h2>Locations en agence</h2>
          <h3>location à partir d'une journée et jusqu'à un mois</h3>
          <p>
            Vous êtes un particulier et vous souhaitez accèder à un véhicule pour des usages personnels?<br />
            Vous êtes un professionnel et vous avez besoin de vous déplacer dans le cadre de votre activité ?<br />
            Accéder à un large choix de véhicules de la gamme VIZION CONNECT.
          </p>
          <h3 style="padding-top:10px">Nous vous proposons :</h3>
          <p><span class="textColeurOrangeFA4300">-</span> Un parcours de véhicules à réserver en agence ou en ligne</p>
          <p><span class="textColeurOrangeFA4300">-</span> Des véhicules récents et entretenus</p>
          <p><span class="textColeurOrangeFA4300">-</span> Une offre accessible à tous et à toutes</p>
        </div>
        <div>
          <div class="floatLeft"><svg-icon type="mdi" :path="path" :size="40"></svg-icon></div>
          <div style="padding-top:10px"><h5>En savoir plus</h5></div>
        </div>
      </div>
      <!-- fin #olonneLocationsEnAgence -->
      <!-- Libre-service -->
      <div class="largeur47pourcentInfos floatRight">
        <img class="imgInfosLocation" src="../assets/libre-service.png" />
        <div class="padding30px">
          <h2>Libre-service</h2>
          <h3>des voitures en autopartage</h3>
          <p>
            Optez pour une location en libre service et devenez autonome dans la mise en place de votre location.<br />
            Optez pour une réservation simple et rapide via votre smartphone et géolocalisez les véhicules au plus proche de vous.
          </p>
          <h3 style="padding-top:10px">Nous vous proposons :</h3>
          <p><span class="textColeurOrangeFA4300">-</span> Une offre 100% mobile</p>
          <p><span class="textColeurOrangeFA4300">-</span> Batterie, assurance et entretien inclus</p>
          <p><span class="textColeurOrangeFA4300">-</span> Tarif en fonction de votre besoin</p>
        </div>
        <div>
          <div class="floatLeft"><svg-icon type="mdi" :path="path" :size="40"></svg-icon></div>
          <div style="padding-top:10px"><h5>En savoir plus</h5></div>
        </div>
      </div>
      <div class="clearBoth"></div>
    </div>
  </div>
</template>
<style lang="scss">
.dp-custom-input {
  box-shadow: 0 0 6px #68AF4D;
  color: #68AF4D;
  
  &:hover {
    border-color: #68AF4D;
  }
}
</style>
