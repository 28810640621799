<script>
import { mapState, mapActions } from 'vuex'
import SvgIcon from '@jamescoyle/vue-icon'
import { mdiCarBattery, mdiCarDoor, mdiCircle } from '@mdi/js'

export default {
  components: {
    SvgIcon
  },
  data() {
    return {
      path: { mdiCarBattery, mdiCarDoor, mdiCircle }
    }
  },
  mounted() {

  },
  computed: {
    ...mapState(['siteFleetListeVehicules',
      'isChoix', 'objSite'])
  },
  methods: {
    ...mapActions(['updateIndexVehiculeChoisi',
      'updateVehicule',
      'updateIsChoix',
      'updateIsOptions',
      'updateIsModePaiement',
      'updateIsInscription',
      'updateIsFinalisation']),
    setIndexVehiculeChoisi(index) {
      this.updateIsChoix(true)
      this.updateIndexVehiculeChoisi(index)
      this.updateVehicule(this.siteFleetListeVehicules[index])
      this.$router.push({ name: 'vehiculeChoisi' })
    }
  }
}
</script>

<template>
  <div class="gammeVehiculesLocation">
    <h1>La gamme des véhicules de location VIZION CONNECT</h1>
    <p>L'offre VIZION CONNECT c'est une large gamme de véhicules qui vous donne la possibilité de choisir le<br />
      véhicule le plus adapté à votre budget, vos besoins et vos envies.<br />
      Vous cherchez à louer un véhicule utilitaire ou un véhicule de tourisme, choisissez la voiture qui correspond à
      votre besoin.
    </p>
  </div>
  <div>
    <v-divider :thickness="10" color="#68AF4D" class="border-opacity-30"></v-divider>
  </div>
  <div class="contenuListeVehicules">
    <div class="floatLeft marginVehiculeListe widthVehicule28pc borderStyle"
      v-for="(vehicule, index) in siteFleetListeVehicules" :key="vehicule">
      <h1>{{ vehicule.model.brand }} {{ vehicule.model.title }}</h1>
      <v-img v-if="vehicule.image" :src="'/car-sharing/api/v1/vehicles/' + vehicule._id + '/image'"
        style="width:100%;" />
      <div v-if="vehicule.model.title != 'S01' || vehicule.model.title != 'S01+' || vehicule.model.title != 'S01 Plus'" class="floatLeftIcon">
        <div class="floatLeftIcon marginRight10px">
          <svg-icon type="mdi" :path="path.mdiCarBattery"></svg-icon>
        </div>
        <div class="floatLeftIcon {{index}}">
          {{ vehicule.model.batteryCapacity }} kw/h
        </div>
      </div>
      <div v-else>
        <div class="floatLeftIcon marginRight10px">
          <svg-icon type="mdi" :path="path.mdiCarBattery"></svg-icon>
        </div>
        <div class="floatLeftIcon marginTop4px">
          {{ vehicule.model.batteryCapacity }} kw/h
        </div>
      </div>
      <div v-if="vehicule.model.title != 'S01' || vehicule.model.title != 'S01+' || vehicule.model.title != 'S01 Plus'" class="floatRightIcon">
        <div class="floatLeftIcon marginRight10px">
          <svg-icon type="mdi" :path="path.mdiCarDoor"></svg-icon>
        </div>
        <div class="floatLeftIcon marginRight50px marginTop4px">
          {{ vehicule.model.nbPlaces }} portes
        </div>
      </div>
      <div style="clear: left"></div>
      <div>
        <div class="floatLeft marginLeft10px">
          <img src="../assets/cercle-vert.png" />
        </div>
        <div class="floatLeft paddingBottom10px margin40">
          Disponible en agence
        </div>
        <div class="floatLeft paddingBottom10px conteneurPrice">
          <p class="pVehicleCard">À partir de {{ vehicule.extras.prixLocation
            }}€/J</p>
        </div>
        <div class="clear--both"></div>
      </div>
      <div style="clear: left"></div>
      <div class="textAlignCenter">
        <v-btn class="text-none text-subtitle-1 btnChoose" color="#68AF4D" rounded="xl"
          @click.prevent="setIndexVehiculeChoisi(index)">
          Choisir ce véhicule
        </v-btn>
      </div>
    </div>
    <div class="clear--left"></div>
  </div>
</template>
