<script>
import { mapState, mapActions } from 'vuex'
import axios from 'axios'
export default {
  data () {
    return {
      dialogInscription: false,
      txt_titre: null,
      txt_prenom: null,
      txt_nomFamille: null,
      txt_email: null,
      txt_confirEmail: null,
      txt_motDePasse: null,
      txt_telephone: null,
      txt_societe: null,
      txt_ligneAdresse1: null,
      txt_ligneAdresse2: null,
      txt_ville: null,
      txt_codePostal: null,
      visible: false,
      txt_emailIdentifiant: null,
      txt_password: null,
      isEmailExisted: false, // si l'adresseEmail existe déjà
      rules: {
        required: value => !!value || 'Required.',
        counter: value => (value != null && value.length <= 20) || 'Max 20 characters',
        mdp: value => (value != null && value.length >= 8) || 'Min 8 characters',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'e-mail non valide.'
        },
        phoneNumber: value => {
          const pattern = /^(33|0)(6|7|9)\d{8}$/
          return pattern.test(value) || 'Numéro non valide'
        }
      }
    }
  },
  mounted () {
    this.onRefreshDialogInscription()
  },
  computed: {
    ...mapState(['isOpenedDialogInscription',
      'titre',
      'prenom',
      'nomFamille',
      'email',
      'confirEmail',
      'motDePasse',
      'telephone',
      'societe',
      'ligneAdresse1',
      'ligneAdresse2',
      'ville',
      'codePostal',
      'isIncription'])
  },
  methods: {
    ...mapActions(['updateIsOpenedDialogInscription',
      'updateTitre',
      'updatePrenom',
      'updateNomFamille',
      'updateEmail',
      'updateConfirEmail',
      'updateMotDePasse',
      'updateTelephone',
      'updateSociete',
      'updateLigneAdresse1',
      'updateLigneAdresse2',
      'updateVille',
      'updateCodePostal',
      'updateIsInscription']),
    onEmailIdentifiantChange (event) {
      this.txt_emailIdentifiant = event.target.value
    },
    onPasswordChange (event) {
      this.txt_password = event.target.value
    },
    async onSeConnecter () {
      if ((this.txt_emailIdentifiant != null && this.txt_emailIdentifiant !== '') &&
      (this.txt_password != null && this.txt_password !== '' && this.txt_password.length >= 8)) {
        const body = {
          identifiant: this.txt_emailIdentifiant,
          motDePasse: this.txt_password
        }
        await this.axios.post('/car-sharing/api/v1/locations/connection/compte', body).then(response => {
          if (response.status === 200) {
            this.updateTitre(response.data.titre)
            this.updatePrenom(response.data.prenom)
            this.updateNomFamille(response.data.nom)
            this.updateEmail(response.data.adresseEmail)
            this.updateTelephone(response.data.telephone)
            this.updateSociete(response.data.nomSociete)
            this.updateLigneAdresse1(response.data.ligneAdresse1)
            this.updateLigneAdresse2(response.data.ligneAdresse2)
            this.updateVille(response.data.ville)
            this.updateCodePostal(response.data.codePostal)
            this.dialogInscription = false
            this.isEmailExisted = false
            this.updateIsInscription(true)
            this.$router.push({ name: 'finalisation' })
          }
        }).catch(error => {
          alert('Votre identifiant/votre mot de passe incorrects.)')
        })
      }
    },
    onCloseDialogInscription () {
      this.dialogInscription = false
      this.onEnregistrementInscription('closeDialogInscription')
    },
    onEnregistrementInscription (origineCall) {
      if ((this.titre != null && this.titre !== '') &&
          (this.prenom != null && this.prenom !== '') &&
          (this.nomFamille != null && this.nomFamille !== '') &&
          (this.email != null && this.email !== '') &&
          (this.confirEmail != null && this.confirEmail !== '') &&
          (this.motDePasse != null && this.motDePasse !== '') &&
          (this.telephone != null && this.telephone !== '') &&
          (this.ligneAdresse1 != null && this.ligneAdresse1 !== '') &&
          (this.ville != null && this.ville !== '') &&
          (this.codePostal != null && this.codePostal !== '')) {
        const body = {
          titre: this.titre,
          prenom: this.prenom,
          nom: this.nomFamille,
          adresseEmail: this.email,
          motDePasse: this.motDePasse,
          telephone: this.telephone,
          nomSociete: this.societe,
          ligneAdresse1: this.ligneAdresse1,
          ligneAdresse2: this.ligneAdresse2,
          ville: this.ville,
          codePostal: this.codePostal
        }
        this.axios.post('/car-sharing/api/v1/locations/verification-email-unique', body).then(response => {
          if (response.status === 200) {
            this.dialogInscription = false
            this.isEmailExisted = false
            this.updateIsInscription(true)
            this.$router.push({ name: 'finalisation' })
          }
          this.updateIsOpenedDialogInscription(this.dialogInscription)
          this.dialogInscription = this.isOpenedDialogInscription
        }).catch(error => {
          this.isEmailExisted = true
        })
        
      } else {
        if (origineCall === 'closeDialogInscription') {
          this.dialogInscription = false
          this.updateIsOpenedDialogInscription(this.dialogInscription)
          this.dialogInscription = this.isOpenedDialogInscription
        } else {
          this.dialogInscription = true
          this.updateIsOpenedDialogInscription(this.dialogInscription)
          this.dialogInscription = this.isOpenedDialogInscription
        }
      }
    },
    onTitreChange (event) {
      this.updateTitre(event.target.value)
    },
    onPrenomChange (event) {
      this.updatePrenom(event.target.value)
    },
    onNomFamilleChange (event) {
      this.updateNomFamille(event.target.value)
    },
    onEmailChange (event) {
      this.updateEmail(event.target.value)
      if (this.confirEmail != null && this.confirEmail !== '') {
        this.updateConfirEmail(null)
        this.txt_confirEmail = null
      }
    },
    onConfirEmailChange (event) {
      if (this.email != null && this.email !== '' && this.email === event.target.value) {
        this.updateConfirEmail(event.target.value)
      } else {
        this.updateConfirEmail(null)
      }
    },
    onMotDePasseChange (event) {
      this.updateMotDePasse(event.target.value)
    },
    onTelephoneChange (event) {
      this.updateTelephone(event.target.value)
    },
    onSocieteChange (event) {
      this.updateSociete(event.target.value)
    },
    onLigneAdresse1Change (event) {
      this.updateLigneAdresse1(event.target.value)
    },
    onLigneAdresse2Change (event) {
      this.updateLigneAdresse2(event.target.value)
    },
    onVilleChange (event) {
      this.updateVille(event.target.value)
    },
    onCodePostalChange (event) {
      this.updateCodePostal(event.target.value)
    },
    onRefreshDialogInscription () {
      if (this.titre != null && this.titre !== '') { this.txt_titre = this.titre }
      if (this.prenom != null && this.prenom !== '') { this.txt_prenom = this.prenom }
      if (this.nomFamille != null && this.nomFamille !== '') { this.txt_nomFamille = this.nomFamille }
      if (this.email != null && this.email !== '') { this.txt_email = this.email }
      if (this.confirEmail != null && this.confirEmail !== '') { this.txt_confirEmail = this.confirEmail }
      if (this.motDePasse != null && this.motDePasse !== '') { this.txt_motDePasse = this.motDePasse }
      if (this.telephone != null && this.telephone !== '') { this.txt_telephone = this.telephone }
      if (this.societe != null && this.societe !== '') { this.txt_societe = this.societe }
      if (this.ligneAdresse1 != null && this.ligneAdresse1 !== '') { this.txt_ligneAdresse1 = this.ligneAdresse1 }
      if (this.ligneAdresse2 != null && this.ligneAdresse2 !== '') { this.txt_ligneAdresse2 = this.ligneAdresse2 }
      if (this.ville != null && this.ville !== '') { this.txt_ville = this.ville }
      if (this.codePostal != null && this.codePostal !== '') { this.txt_codePostal = this.codePostal }
    }
  }
}
</script>

<template>
  <div class="gammeVehiculesLocation">
    <h1 class="h1-25">Identifiez ou créez votre compte</h1>
  </div>
  <div>
    <div class="width45pc margin25px floatLeftCon">
      <h2>Se connecter</h2>
      <v-card
        class="mx-auto pa-12 pb-8"
        elevation="8"
        width="100%"
        rounded="lg"
      >
        <div class="text-subtitle-1 text-medium-emphasis">Email</div>

        <v-text-field
          density="compact"
          placeholder="Saisissez votre email"
          prepend-inner-icon="mdi-email-outline"
          variant="outlined"
          v-model="txt_emailIdentifiant"
          :rules="[rules.required, rules.email]"
          @change="onEmailIdentifiantChange"
        ></v-text-field>

        <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
          Mot de passe
          <!--<a
            class="text-caption text-decoration-none text-blue"
            href="#"
            rel="noopener noreferrer"
            target="_blank"
          >
            Mot de passe oublié?</a>-->
        </div>

        <v-text-field
          :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
          :type="visible ? 'text' : 'password'"
          density="compact"
          placeholder="Saisissez votre mot de passe"
          prepend-inner-icon="mdi-lock-outline"
          variant="outlined"
          v-model="txt_password"
          :rules="[rules.required, rules.mdp]"
          @click:append-inner="visible = !visible"
          @change="onPasswordChange"
        ></v-text-field>
        <p class="text--align--center">
          <v-btn class="text-none text-subtitle-1 bt--rechercher"
              color="#68AF4D"
              rounded="xl"
              width="40%"
              @click="onSeConnecter">
          Valider
          </v-btn>
        </p>
      </v-card>
    </div>
    <!-- création de compte -->
    <div class="width45pc margin25px floatRightCon">
      <h2>Créer mon compte</h2>
      <v-card
        class="mx-auto pa-12 pb-8"
        elevation="8"
        width="100%"
        height="300px"
        rounded="lg"
      >
        <h3>Pourquoi posséder un compte?</h3>
        <ul>
          <li>Accéder à votre profil et gérez vos informations personnelles</li>
          <li>Accès rapide à la demande de réservation</li>
        </ul>
        <p class="text--align--center inscriptionBtn">
          <v-btn class="text-none text-subtitle-1 bt--rechercher"
              color="#68AF4D"
              rounded="xl"
              width="50%"
              @click="dialogInscription = true"
          >
          M'inscrire
          </v-btn>
        </p>
      </v-card>
    </div>
  </div>
  <div class="clear-both"></div>
  <!-- Infos de la personne -->
  <div class="text-center">
    <v-row justify="center">
      <v-dialog
        class="dialogInscription"
        v-model="dialogInscription"
        persistent
      >
        <v-card>
        <v-card-text>
          <v-container>
            <h3 class="text--align--center">VOS COORDONNÉES</h3>
            <div class="width330">
                <p class="bold">Titre
                <span v-if="titre != null && titre !== ''">*</span>
                <span v-else class="champObligatoire">*</span></p>
                <p>
                  <v-text-field
                    style="padding:0px;margin:0px;"
                    variant="solo-filled"
                    required
                    @change="onTitreChange"
                    v-model="txt_titre"
                  ></v-text-field>
                </p>
            </div>
            <!-- couple 1 -->
            <div class="width330 floatLeftCon">
                <p class="bold">Prénom
                <span v-if="prenom != null && prenom !== ''">*</span>
                <span v-else class="champObligatoire">*</span></p>
                <p>
                  <v-text-field
                    style="padding:0px;margin:0px;"
                    variant="solo-filled"
                    required
                    @change="onPrenomChange"
                    v-model="txt_prenom"
                    :rules="[rules.counter]"
                  ></v-text-field>
                </p>
            </div>
            <div class="width330 floatRightCon">
                <p class="bold">Nom de famille
                <span v-if="nomFamille != null && nomFamille !== ''">*</span>
                <span v-else class="champObligatoire">*</span></p>
                <p>
                  <v-text-field
                    style="padding:0px;margin:0px;"
                    variant="solo-filled"
                    @change="onNomFamilleChange"
                    v-model="txt_nomFamille"
                    :rules="[rules.counter]"
                  >
                  </v-text-field>
                </p>
            </div>
            <!-- fin couple 1 -->
            <div class="clear--both"></div>
            <!-- couple 2 -->
            <div class="width330 floatLeftCon">
                <p class="bold">Adresse e-mail
                <span v-if="email != null && email !== ''">*</span>
                <span v-else class="champObligatoire">*</span></p>
                <p>
                  <v-text-field
                    style="padding:0px;margin:0px;"
                    variant="solo-filled"
                    @change="onEmailChange"
                    v-model="txt_email"
                    :rules="[rules.required, rules.email]"
                  >
                  </v-text-field>
                </p>
            </div>
            <div class="width330 floatRightCon">
                <p class="bold">Confirmez votre adresse e-mail
                <span v-if="confirEmail != null && confirEmail !== ''">*</span>
                <span v-else class="champObligatoire">*</span></p>
                <p>
                  <v-text-field
                    style="padding:0px;margin:0px;"
                    variant="solo-filled"
                    @change="onConfirEmailChange"
                    v-model="txt_confirEmail"
                    :rules="[rules.required, rules.email]"
                  >
                  </v-text-field>
                </p>
            </div>
            <p v-if="isEmailExisted" style="color:#FA4339;clear: both;padding-bottom: 20px;">
                  L'adresse-email {{ txt_email }} existe déjà !
                </p>
            <!-- fin couple 2 -->
            <div class="clear--both"></div>
            <div class="width330 floatLeftCon">
                <p class="bold">Téléphone
                <span v-if="telephone != null && telephone !== ''">*</span>
                <span v-else class="champObligatoire">*</span></p>
                <p>
                  <v-text-field
                    style="padding:0px;margin:0px;"
                    variant="solo-filled"
                    required
                    @change="onTelephoneChange"
                    v-model="txt_telephone"
                    :rules="[rules.phoneNumber]"
                  ></v-text-field>
                </p>
            </div>
            <div class="width330 floatRightCon">
                <p class="bold">Créez votre mot de passe
                <span v-if="motDePasse != null && motDePasse !== ''">*</span>
                <span v-else class="champObligatoire">*</span></p>
                <p>
                  <v-text-field
                    style="padding:0px;margin:0px;"
                    variant="solo-filled"
                    @change="onMotDePasseChange"
                    v-model="txt_motDePasse"
                    :rules="[rules.required, rules.mdp]"
                  >
                  </v-text-field>
                </p>
            </div>
            <div class="clear--both"></div>
            <h3>ADRESSE DE FACTURATION</h3>
            <div class="width330">
                <p class="bold">Nom de la société</p>
                <p>
                  <v-text-field
                    style="padding:0px;margin:0px;"
                    variant="solo-filled"
                    required
                    @change="onSocieteChange"
                    v-model="txt_societe"
                  ></v-text-field>
                </p>
            </div>
            <!-- couple 3 -->
            <div class="width330 floatLeftCon">
                <p class="bold">Ligne d'adresse 1
                <span v-if="ligneAdresse1 != null && ligneAdresse1 !== ''">*</span>
                <span v-else class="champObligatoire">*</span></p>
                <p>
                  <v-text-field
                    style="padding:0px;margin:0px;"
                    variant="solo-filled"
                    @change="onLigneAdresse1Change"
                    v-model="txt_ligneAdresse1"
                    :rules="[rules.counter]"
                  >
                  </v-text-field>
                </p>
            </div>
            <div class="width330 floatRightCon">
                <p class="bold">Ligne d'adresse 2</p>
                <p>
                  <v-text-field
                    style="padding:0px;margin:0px;"
                    variant="solo-filled"
                    @change="onLigneAdresse2Change"
                    v-model="txt_ligneAdresse2"
                  >
                  </v-text-field>
                </p>
            </div>
            <!-- fin couple 3 -->
            <!-- couple 4 -->
            <div class="width330 floatLeftCon">
                <p class="bold">Ville
                <span v-if="ville != null && ville !== ''">*</span>
                <span v-else class="champObligatoire">*</span></p>
                <p>
                  <v-text-field
                    style="padding:0px;margin:0px;"
                    variant="solo-filled"
                    @change="onVilleChange"
                    v-model="txt_ville"
                    :rules="[rules.counter]"
                  >
                  </v-text-field>
                </p>
            </div>
            <div class="width330 floatRightCon">
                <p class="bold">Code postal
                <span v-if="codePostal != null && codePostal !== ''">*</span>
                <span v-else class="champObligatoire">*</span></p>
                <p>
                  <v-text-field
                    style="padding:0px;margin:0px;"
                    variant="solo-filled"
                    @change="onCodePostalChange"
                    v-model="txt_codePostal"
                    min-length = "5"
                    max-length = "5"
                  >
                  </v-text-field>
                </p>
            </div>
            <!-- fin couple 4 -->
            <div class="clear--both"></div>
          </v-container>
          <small>* champs obligatoires</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#68AF4D"
            variant="text"
            @click="onCloseDialogInscription"
          >
            Fermer
          </v-btn>
          <v-btn
            color="#68AF4D"
            variant="text"
            @click="onEnregistrementInscription"
          >
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
