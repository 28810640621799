<script>
import { mapState, mapActions } from 'vuex'
const JOURNEE_24_HEURES_MILLIS = Number(24 * 60 * 60 * 1000)
export default {
  name: 'ReservationView',
  components: {
  },
  data() {
    return {
      isAcceptation: false,
      checkBoxAcceptation: false,
      isAnnuler: true,
      isRemplieCartePaiement: false,
      sel_moisCartePaiement: null,
      sel_anneeCartePaiement: null,
      txt_numeroCartePaiement: null,
      txt_nomCartePaiement: null,
      txt_numCVVCartePaiement: null,
      dialogInfo: false,
      messageDialogInfo: '',
      hasErreur: true,
      prixTotalLocation: null
    }
  },
  computed: {
    ...mapState(['lieuDepartRetour',
      'dateHeureDepart',
      'dateHeureRetour',
      'typeLocation',
      'isAgePlus25ans',
      'siteFleetListeVehicules',
      'indexVehiculeChoisi',
      'owner',
      'siteFleetName',
      'vehicule',
      'moyenPaiement',
      'numeroCartePaiement',
      'nomCartePaiement',
      'dateExpirattionCartePaiement',
      'moisExpirationCartePaiement',
      'anneeExpirationCartePaiement',
      'numeroCVV',
      'isRemplisChampsCartePaiement',
      'titre',
      'prenom',
      'nomFamille',
      'email',
      'telephone',
      'societe',
      'ligneAdresse1',
      'ligneAdresse2',
      'ville',
      'codePostal',
      'isIncription',
      'isFinalisation'])
  },
  methods: {
    ...mapActions(['updateLieuDepartRetour',
      'updateDateHeureDepart',
      'updateDateHeureRetour',
      'updateTypeLocation',
      'updateIsAgePlus25ans',
      'updateIsInscription',
      'updateIsFinalisation',
      'updateNumeroCartePaiement',
      'updateNomCartePaiement',
      'updateDateExpirationCartePaiement',
      'updateMoisExpirationCartePaiement',
      'updateAnneeExpirationCartePaiement',
      'updateNumeroCVV',
      'updateIsRemplisChampsCartePaiement']),
    onCalculPrixTotalLocation() {
      if (this.vehicule.extras != null && this.vehicule.extras.prixLocation != null) {
        var tmpDateHeureDepart = this.dateHeureDepart
        var pDateHeureDepart = tmpDateHeureDepart.replace(/(..)-(..)-(....) (..):(..)/, '$3-$2-$1 $4:$5')
        var pDateHeureDepartMillisSecondes = new Date(pDateHeureDepart).getTime()

        var tmpDateHeureRetour = this.dateHeureRetour
        var pDateHeureRetour = tmpDateHeureRetour.replace(/(..)-(..)-(....) (..):(..)/, '$3-$2-$1 $4:$5')
        var pDateRetourMillisSecondes = new Date(pDateHeureRetour).getTime()

        const diff = pDateRetourMillisSecondes - pDateHeureDepartMillisSecondes
        const prix = (Number(this.vehicule.extras.prixLocation) * Math.ceil(diff / JOURNEE_24_HEURES_MILLIS)).toFixed(2)
        return prix

      }
      return null
    },
    onChangeCheckBoxAcceptation(event) {
      this.isAcceptation = event.target.checked
      if (this.isAcceptation) {
        this.isAnnuler = false
      } else {
        this.isAnnuler = true
        this.isAcceptation = false
        this.checkBoxAcceptation = false
      }
    },
    onValidation() {
      if (this.isAcceptation) {
        this.axios.post('/car-sharing/api/v1/locations',
          {
            titre: this.titre,
            prenom: this.prenom,
            nom: this.nomFamille,
            adresseEmail: this.email,
            telephone: this.telephone,
            nomSociete: this.societe,
            ligneAdresse1: this.ligneAdresse1,
            ligneAdresse2: this.ligneAdresse2,
            ville: this.ville,
            codePostal: this.codePostal,
            typeLocation: this.typeLocation + (this.isAgePlus25ans ? ' de + de 25 ans' : ''),
            lieuDepartRetour: this.lieuDepartRetour,
            dateHeureDepart: this.dateHeureDepart,
            dateHeureRetour: this.dateHeureRetour,
            vehicle: {
              _id: this.vehicule._id,
              title: this.vehicule.model.brand + ' ' + this.vehicule.model.title,
              batteryCapacity: this.vehicule.model.batteryCapacity != null ? Number(this.vehicule.model.batteryCapacity) : null,
              nbPortes: this.vehicule.model.nbPortes != null ? Number(this.vehicule.model.nbPortes) : null,
              prixLocation: this.onCalculPrixTotalLocation() != null ? Number(this.onCalculPrixTotalLocation()) : null
            },
            owner: {
              id: this.owner.id,
              type: this.owner.type,
              name: this.owner.name
            },
            fleetName: this.siteFleetName
          }).
          then(response => {
            if (response.status === 200) {
              this.messageDialogInfo = 'Nous avons bien reçu votre réservation. Un courrier de confirmation a été envoyé à votre mail.'
              this.hasErreur = false
              this.dialogInfo = true
            } else {
              this.hasErreur = true
              this.messageDialogInfo = response
              this.dialogInfo = true
            }
          }).
          catch(error => {
            this.hasErreur = true
            this.messageDialogInfo = "Veuillez choisir une autre date de réservation."
            this.dialogInfo = true
          })
      }
    },
    onAnnuler() {
      this.updateIsFinalisation(true)
      this.$router.push({ name: 'connectionInscription' })
    },
    onDialogInfo() {
      this.dialogInfo = false
      if (!this.hasErreur) {
        this.$router.push({ name: 'reservation' })
      }
    }
  }
}
</script>
<template>
  <div class="finalisation">
    <h1>Finalisation de votre réservation</h1>
  </div>
  <div>
    <v-divider :thickness="10" color="#68AF4D" class="border-opacity-30"></v-divider>
  </div>
  <!-- Infos de la personne -->
  <div>
    <v-row justify="center">
      <v-card width="800px">
        <v-card-text>
          <v-container>
            <h3 class="text-center espace10px">VOS COORDONNÉES</h3>
            <div style="width:330px;">
              <p class="bold">Titre</p>
              <p>
                <v-text-field style="padding:0px;margin:0px;" variant="solo-filled">{{ titre }}</v-text-field>
              </p>
            </div>
            <!-- couple 1 -->
            <div style="width:330px; float:left;">
              <p class="bold">Prénom</p>
              <p>
                <v-text-field style="padding:0px;margin:0px;" variant="solo-filled">{{ prenom }}</v-text-field>
              </p>
            </div>
            <div style="width:330px; float:right;">
              <p class="bold">Nom de famille</p>
              <p>
                <v-text-field style="padding:0px;margin:0px;" variant="solo-filled">{{ nomFamille }}</v-text-field>
              </p>
            </div>
            <!-- fin couple 1 -->
            <div class="clear--both"></div>
            <!-- couple 2 -->
            <div style="width:330px; float:left;">
              <p class="bold">Adresse e-mail</p>
              <p>
                <v-text-field style="padding:0px;margin:0px;" variant="solo-filled">{{ email }}</v-text-field>
              </p>
            </div>
            <div style="width:330px; float:right;">
              <p class="bold">Téléphone</p>
              <p>
                <v-text-field style="padding:0px;margin:0px;" variant="solo-filled">{{ telephone }}</v-text-field>
              </p>
            </div>
            <div class="clear--both"></div>
            <h3 class="text-center espace10px">ADRESSE DE FACTURATION</h3>
            <div style="width:330px; float:left;">
              <p class="bold">Nom de la société</p>
              <p>
                <v-text-field style="padding:0px;margin:0px;" variant="solo-filled">{{ societe }}</v-text-field>
              </p>
            </div>
            <div class="clear--left"></div>
            <div style="width:330px; float:left;">
              <p class="bold">Ligne d'adresse 1</p>
              <p>
                <v-text-field style="padding:0px;margin:0px;" variant="solo-filled">{{ ligneAdresse1 }}</v-text-field>
              </p>
            </div>
            <div style="width:330px; float:right;">
              <p class="bold">Ligne d'adresse 2</p>
              <p>
                <v-text-field style="padding:0px;margin:0px;" variant="solo-filled">{{ ligneAdresse2 }}</v-text-field>
              </p>
            </div>
            <div class="clear--both"></div>
            <div style="width:330px; float:left;">
              <p class="bold">Ville</p>
              <p>
                <v-text-field style="padding:0px;margin:0px;" variant="solo-filled">{{ ville }}</v-text-field>
              </p>
            </div>
            <div style="width:330px; float:right;">
              <p class="bold">Code postal</p>
              <p>
                <v-text-field style="padding:0px;margin:0px;" variant="solo-filled">{{ codePostal }}</v-text-field>
              </p>
            </div>
            <div class="clear--both"></div>
            <h3 class="text-center espace10px">VOTRE LOCATION</h3>
            <div style="width:330px; float:left;">
              <p class="bold">Type de location</p>
              <p>
                <v-text-field style="padding:0px;margin:0px;" variant="solo-filled">{{ typeLocation }}&nbsp;<span
                    v-if="isAgePlus25ans">de + de {{ 25 }} ans</span></v-text-field>
              </p>
            </div>
            <div style="width:330px; float:right;">
              <p class="bold">Lieu de départ et de retour</p>
              <p>
                <v-text-field style="padding:0px;margin:0px;" variant="solo-filled">{{ lieuDepartRetour
                  }}</v-text-field>
              </p>
            </div>
            <div class="clear--both"></div>
            <div style="width:330px; float:left;">
              <p class="bold">Date et heure de départ</p>
              <p>
                <v-text-field style="padding:0px;margin:0px;" variant="solo-filled">{{ dateHeureDepart }}</v-text-field>
              </p>
            </div>
            <div style="width:330px; float:right;">
              <p class="bold">Date et heure de retour</p>
              <p>
                <v-text-field style="padding:0px;margin:0px;" variant="solo-filled">{{ dateHeureRetour }}</v-text-field>
              </p>
            </div>
            <div class="clear--both"></div>
            <h3 class="text-center espace10px">VOTRE VÉHICULE</h3>
            <div style="width:330px; float:left;">
              <p class="bold">Véhicule</p>
              <p>
                <v-text-field style="padding:0px;margin:0px;" variant="solo-filled">{{
      siteFleetListeVehicules[indexVehiculeChoisi].model.brand }} {{
      siteFleetListeVehicules[indexVehiculeChoisi].model.title }}</v-text-field>
              </p>
            </div>
            <div style="width:330px; float:right;">
              <p class="bold">Prix</p>
              <p>
                <v-text-field style="padding:0px;margin:0px;" variant="solo-filled">À partir de {{
      onCalculPrixTotalLocation() != null ?
        onCalculPrixTotalLocation() : '' }} €</v-text-field>
              </p>
            </div>
            <div class="clear--both"></div>
            <div style="width:330px; float:left;">
              <p class="bold">Batterie</p>
              <p>
                <v-text-field style="padding:0px;margin:0px;" variant="solo-filled">{{
      siteFleetListeVehicules[indexVehiculeChoisi].model.batteryCapacity }} kw/h</v-text-field>
              </p>
            </div>
            <div style="width:330px; float:right;">
              <p class="bold">Options</p>
              <p>
                <v-text-field style="padding:0px;margin:0px;" variant="solo-filled">...</v-text-field>
              </p>
            </div>
            <div class="clear--both"></div>
            <div>
              <v-checkbox label="J'accepte les termes de conditions ..." v-model="checkBoxAcceptation" :value=true
                @change.prevent="onChangeCheckBoxAcceptation">
              </v-checkbox>
            </div>
            <div v-if="isAnnuler">
              <v-btn class="text-none text-subtitle-1 bt--rechercher" color="#68AF4D" rounded="xl" width="100"
                @click.prevent="onAnnuler">
                Annuler
              </v-btn>
            </div>
            <div v-if="isAcceptation" style="text-align:center">
              <v-btn class="text-none text-subtitle-1 bt--rechercher" color="#68AF4D" rounded="xl" width="250"
                @click.prevent="onValidation">
                VALIDER
              </v-btn>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-row>
  </div>
  <v-row justify="center">
    <v-dialog v-model="dialogInfo" persistent width="auto">
      <v-card>
        <v-card-title class="text-h5">
          Message d'information
        </v-card-title>
        <v-card-text>{{ messageDialogInfo }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green-darken-1" variant="text" @click="onDialogInfo">
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
