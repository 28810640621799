import { createStore } from 'vuex'

export default createStore({
  state: {
    /* Fil d'Ariane */
    isReservation: false,
    isChoix: false,
    isOptions: false,
    isModePaiement: false,
    isIncription: false,
    isFinalisation: false,
    /* Début de la réservation */
    lieuDepartRetour: null,
    dateHeureDepart: null,
    dateHeureRetour: null,
    typeLocation: 'Particulier',
    isAgePlus25ans: false,
    /* Etat des liens du menu haut principal */
    isInfosErreurs: false,
    routerLinkHomeIsActive: true,
    routerLinkTrouverUneAgenceIsActive: false,
    routerLinkProfessionnelIsActive: false,
    routerLinkFaqIsActive: false,
    routerLinkMeConnecterIsActive: false,
    /* fin Etat des liens du menu haut principal */
    /* Variables d'un site : à partir de ces 3 variables, on retrouve la liste des véhicules */
    /* Première requête find in sites where site.host = {{ siteHostName }} => retourne un site contenant le nom de la flotte */
    /* A partir du nom de la flotte, on fait une requête dans la collection 'vehicles' : */
    /* find in 'vehicles' where fleet.name = 'fleetName' and owner.name = {{ siteOwnerName }} */
    /* vehicle : title, vin, model */
    objSite: null,
    siteHostName: null,
    owner: {},
    siteFleetName: null,
    siteFleetListeVehicules: [],
    /* fin données site */
    /* Variable stockant le véhicule loué choisi par le particulier */
    indexVehiculeChoisi: 0,
    vehicule: {},
    listeAgences: [],
    /* carte de paiement */
    moyenPaiement: null,
    numeroCartePaiement: null,
    nomCartePaiement: null,
    dateExpirattionCartePaiement: null,
    numeroCVV: null,
    moisExpirationCartePaiement: null,
    anneeExpirationCartePaiement: null,
    isRemplisChampsCartePaiement: false,
    /* infos de la personne */
    titre: null,
    prenom: null,
    nomFamille: null,
    email: null,
    confirEmail: null,
    telephone: null,
    motDePasse: null,
    societe: null,
    ligneAdresse1: null,
    ligneAdresse2: null,
    ville: null,
    codePostal: null,
    isOpenedDialogInscription: false
  },
  getters: {
    hasDateExpirattionCartePaiement: state => {
      if (state.moisExpirationCartePaiement != null && state.anneeExpirationCartePaiement != null) {
        return state.moisExpirationCartePaiement + '-' + state.anneeExpirationCartePaiement
      }
      return null
    }
  },
  mutations: {
    UPDATE_OBJ_SITE(state, s) {
      state.objSite = s
    },
    UPDATE_LISTE_AGENCES(state, liste) {
      state.listeAgences = liste
    },
    UPDATE_IS_FINALISATION(state, s) {
      state.isFinalisation = s
    },
    UPDATE_IS_RESERVATION(state, s) {
      state.isReservation = s
    },
    UPDATE_IS_CHOIX(state, s) {
      state.isChoix = s
    },
    UPDATE_IS_OPTIONS(state, s) {
      state.isOptions = s
    },
    UPDATE_IS_MODE_PAIMENT(state, s) {
      state.isModePaiement = s
    },
    UPDATE_IS_INSCRIPTION(state, s) {
      state.isIncription = s
    },
    UPDATE_IS_OPENED_DIALOG_INSCRIPTION(state, open) {
      state.isOpenedDialogInscription = Boolean(open)
    },
    UPDATE_TITRE(state, titre) {
      state.titre = titre
    },
    UPDATE_PRENOM(state, prenom) {
      state.prenom = prenom
    },
    UPDATE_NOM_FAMILLE(state, nom) {
      state.nomFamille = nom
    },
    UPDATE_EMAIL(state, mail) {
      state.email = mail
    },
    UPDATE_CONFIR_EMAIL(state, mail) {
      state.confirEmail = mail
    },
    UPDATE_MOT_DE_PASSE(state, mail) {
      state.motDePasse = mail
    },
    UPDATE_TELEPHONE(state, phone) {
      state.telephone = phone
    },
    UPDATE_SOCIETE(state, societe) {
      state.societe = societe
    },
    UPDATE_LIGNE_ADRESSE_1(state, ligne1) {
      state.ligneAdresse1 = ligne1
    },
    UPDATE_LIGNE_ADRESSE_2(state, ligne2) {
      state.ligneAdresse2 = ligne2
    },
    UPDATE_VILLE(state, ville) {
      state.ville = ville
    },
    UPDATE_CODE_POSTAL(state, cp) {
      state.codePostal = cp
    },
    /* Début de la réservation */
    UPDATE_LIEU_DEPART_RETOUR(state, valueString) {
      state.lieuDepartRetour = valueString
    },
    UPDATE_DATE_HEURE_DEPART(state, valueString) {
      state.dateHeureDepart = valueString
    },
    UPDATE_DATE_HEURE_RETOUR(state, valueString) {
      state.dateHeureRetour = valueString
    },
    UPDATE_TYPE_LOCATION(state, valueString) {
      state.typeLocation = valueString
    },
    UPDATE_IS_AGE_PLUS_25_ANS(state, valueBoolean) {
      state.isAgePlus25ans = Boolean(valueBoolean)
    },
    /* Fin Début de la réservation */
    /* Etat des liens du menu haut principal */
    UPDATE_IS_INFOS_ERREURS(state, valueBoolean) {
      state.isInfosErreurs = Boolean(valueBoolean)
    },
    UPDATE_ROUTER_LINK_HOME(state, valueString) {
      state.routerLinkHomeIsActive = valueString
    },
    UPDATE_ROUTER_LINK_TROUVER_AGENCE(state, valueString) {
      state.routerLinkTrouverUneAgenceIsActive = valueString
    },
    UPDATE_ROUTER_LINK_PROFESSIONNEL(state, valueString) {
      state.routerLinkProfessionnelIsActive = valueString
    },
    UPDATE_ROUTER_LINK_FAQ(state, valueString) {
      state.routerLinkFaqIsActive = valueString
    },
    UPDATE_ROUTER_LINK_ME_CONNECTER(state, valueString) {
      state.routerLinkMeConnecterIsActive = valueString
    },
    /* fin Etat des liens du menu haut principal */
    /* Infos relatives au Site */
    SITE_HOST_NAME(state, siteName) {
      state.siteHostName = siteName
    },
    UPDATE_OWNER(state, objOwner) {
      state.owner = objOwner
    },
    SITE_FLEET_NAME(state, fleetName) {
      state.siteFleetName = fleetName
    },
    SITE_FLEET_LISTE_VEHICULES(state, listeFleetVehiculesArrayType) {
      state.siteFleetListeVehicules = listeFleetVehiculesArrayType
    },
    INDEX_VEHICULE_CHOISI(state, indexVehiculeNumberType) {
      state.indexVehiculeChoisi = Number(indexVehiculeNumberType)
    },
    UPDATE_VEHICULE(state, objVehicule) {
      state.vehicule = objVehicule
    },
    /* fin Infos relatives au Site */
    UPDATE_MOYEN_PAIEMENT(state, moyPaiement) {
      state.moyenPaiement = moyPaiement
    },
    UPDATE_NUMERO_CARTE_PAIEMENT(state, numero) {
      state.numeroCartePaiement = Number(numero)
    },
    UPDATE_NOM_CARTE_PAIEMENT(state, nom) {
      state.nomCartePaiement = nom
    },
    UPDATE_DATE_EXPIRATION_CARTE_PAIEMENT(state, dateExpiration) {
      state.dateExpirattionCartePaiement = dateExpiration
    },
    UPDATE_MOIS_EXPIRATION_CARTE_PAIEMENT(state, moisExpiration) {
      state.moisExpirationCartePaiement = moisExpiration
    },
    UPDATE_ANNEE_EXPIRATION_CARTE_PAIEMENT(state, anneeExpiration) {
      state.anneeExpirationCartePaiement = anneeExpiration
    },
    UPDATE_NUMERO_CVV(state, numCVV) {
      state.numeroCVV = Number(numCVV)
    },
    UPDATE_IS_REMPLIS_CHAMPS_CARTE_PAIEMENT(state, isRepmlis) {
      state.isRemplisChampsCartePaiement = isRepmlis
    }
  },
  actions: {
    updateObjSite({ commit }, t) {
      commit('UPDATE_OBJ_SITE', t)
    },
    updateListeAgences({ commit }, t) {
      commit('UPDATE_LISTE_AGENCES', t)
    },
    updateIsFinalisation({ commit }, t) {
      commit('UPDATE_IS_FINALISATION', t)
    },
    updateIsReservation({ commit }, t) {
      commit('UPDATE_IS_RESERVATION', t)
    },
    updateIsChoix({ commit }, t) {
      commit('UPDATE_IS_CHOIX', t)
    },
    updateIsOptions({ commit }, t) {
      commit('UPDATE_IS_OPTIONS', t)
    },
    updateIsModePaiement({ commit }, t) {
      commit('UPDATE_IS_MODE_PAIMENT', t)
    },
    updateIsInscription({ commit }, t) {
      commit('UPDATE_IS_INSCRIPTION', t)
    },
    updateIsOpenedDialogInscription({ commit }, t) {
      commit('UPDATE_IS_OPENED_DIALOG_INSCRIPTION', t)
    },
    updateTitre({ commit }, t) {
      commit('UPDATE_TITRE', t)
    },
    updatePrenom({ commit }, t) {
      commit('UPDATE_PRENOM', t)
    },
    updateNomFamille({ commit }, t) {
      commit('UPDATE_NOM_FAMILLE', t)
    },
    updateEmail({ commit }, t) {
      commit('UPDATE_EMAIL', t)
    },
    updateConfirEmail({ commit }, t) {
      commit('UPDATE_CONFIR_EMAIL', t)
    },
    updateMotDePasse({ commit }, t) {
      commit('UPDATE_MOT_DE_PASSE', t)
    },
    updateTelephone({ commit }, t) {
      commit('UPDATE_TELEPHONE', t)
    },
    updateSociete({ commit }, t) {
      commit('UPDATE_SOCIETE', t)
    },
    updateLigneAdresse1({ commit }, t) {
      commit('UPDATE_LIGNE_ADRESSE_1', t)
    },
    updateLigneAdresse2({ commit }, t) {
      commit('UPDATE_LIGNE_ADRESSE_2', t)
    },
    updateVille({ commit }, t) {
      commit('UPDATE_VILLE', t)
    },
    updateCodePostal({ commit }, t) {
      commit('UPDATE_CODE_POSTAL', t)
    },
    /* Début de la réservation */
    updateLieuDepartRetour({ commit }, lieu) {
      commit('UPDATE_LIEU_DEPART_RETOUR', lieu)
    },
    updateDateHeureDepart({ commit }, dateString) {
      commit('UPDATE_DATE_HEURE_DEPART', dateString)
    },
    updateDateHeureRetour({ commit }, dateString) {
      commit('UPDATE_DATE_HEURE_RETOUR', dateString)
    },
    updateTypeLocation({ commit }, valueString) {
      commit('UPDATE_TYPE_LOCATION', valueString)
    },
    updateIsAgePlus25ans({ commit }, valueBoolean) {
      commit('UPDATE_IS_AGE_PLUS_25_ANS', valueBoolean)
    },
    /* fin Début de la réservation */
    /* Infos relatives au Site */
    updateSiteHostName({ commit }, siteName) {
      commit('SITE_HOST_NAME', siteName)
    },
    updateSiteOwner({ commit }, objOwner) {
      commit('UPDATE_OWNER', objOwner)
    },
    updateSiteFleetName({ commit }, fleetName) {
      commit('SITE_FLEET_NAME', fleetName)
    },
    updateSiteFleetListeVehicules({ commit }, listeFleetVehicules) {
      commit('SITE_FLEET_LISTE_VEHICULES', listeFleetVehicules)
    },
    updateIndexVehiculeChoisi({ commit }, indexVehicule) {
      commit('INDEX_VEHICULE_CHOISI', indexVehicule)
    },
    updateVehicule({ commit }, objVehicule) {
      commit('UPDATE_VEHICULE', objVehicule)
    },
    updateIsInfosErreurs({ commit }, valueBoolean) {
      commit('UPDATE_IS_INFOS_ERREURS', valueBoolean)
    },
    updateMoyenPaiement({ commit }, moyPaiement) {
      commit('UPDATE_MOYEN_PAIEMENT', moyPaiement)
    },
    /* fin Infos relatives au Site */
    updateNumeroCartePaiement({ commit }, numero) {
      commit('UPDATE_NUMERO_CARTE_PAIEMENT', numero)
    },
    updateNomCartePaiement({ commit }, nom) {
      commit('UPDATE_NOM_CARTE_PAIEMENT', nom)
    },
    updateDateExpirationCartePaiement({ commit }, dateExpiration) {
      commit('UPDATE_DATE_EXPIRATION_CARTE_PAIEMENT', dateExpiration)
    },
    updateMoisExpirationCartePaiement({ commit }, moisExpiration) {
      commit('UPDATE_MOIS_EXPIRATION_CARTE_PAIEMENT', moisExpiration)
    },
    updateAnneeExpirationCartePaiement({ commit }, anneeExpiration) {
      commit('UPDATE_ANNEE_EXPIRATION_CARTE_PAIEMENT', anneeExpiration)
    },
    updateNumeroCVV({ commit }, numCVV) {
      commit('UPDATE_NUMERO_CVV', numCVV)
    },
    updateIsRemplisChampsCartePaiement({ commit }, isRemplis) {
      commit('UPDATE_IS_REMPLIS_CHAMPS_CARTE_PAIEMENT', isRemplis)
    }
  },
  modules: {
  }
})
