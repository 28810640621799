<script>
import { mapState, mapActions } from 'vuex'
export default {
  computed: {
    ...mapState(['lieuDepartRetour',
      'dateHeureDepart',
      'dateHeureRetour',
      'typeLocation',
      'isAgePlus25ans',
      'siteFleetListeVehicules',
      'indexVehiculeChoisi',
      'isOptions'])
  },
  mounted () {
  },
  methods: {
    ...mapActions(['updateIndexVehiculeChoisi',
      'updateIsOptions',
      'updateIsModePaiement',
      'updateIsInscription']),
    setIndexVehiculeChoisi (index) {
      this.updateIndexVehiculeChoisi(index)
    },
    onPageConnectionInscription () {
      this.updateIsOptions(true)
      this.$router.push({ name: 'moyensPaiements' })
    }
  }
}
</script>

<template>
  <div class="gammeVehiculesLocation">
    <h1>Récapitulatif de votre réservation</h1>
    <p>L'offre VIZION CONNECT c'est une large gamme de véhicules qui vous donne la possibilité de choisir le<br />
    véhicule le plus adapté à votre budget, vos besoins et vos envies.<br />
    Vous cherchez à louer un véhicule utilitaire ou un véhicule de tourisme, choisissez la voiture qui correspond à votre besoin.
    </p>
  </div>
  <div>
    <v-divider :thickness="10" color="#68AF4D" class="border-opacity-30"></v-divider>
  </div>
  <!-- Partie gauche : le véhicule sélectionné -->
  <div class="marginVehiculeOption height400px">
    <div class="widthVehicule borderStyle height400px conteneurDetails">
        <h1>Détails et options</h1>
        <h2>{{ siteFleetListeVehicules[indexVehiculeChoisi].model.brand }} {{ siteFleetListeVehicules[indexVehiculeChoisi].model.title  }}</h2>
        <p><span class="float--left margin30px left600">Jours supplémentaires : 0 jour</span><span class="float--right margin30px right600">0,00 &euro;</span></p>
        <p class="clearBoth"></p>
        <p><span class="float--left margin30px left600">Garantie individuelle conducteur</span><span class="float--right margin30px right600">Incluse</span></p>
        <p class="clearBoth"></p>
        <p><span class="float--left margin30px left600">Connectez-vous pour ajouter un conducteur (offert)</span></p>
        <p class="clearLeft"></p>
        <p><span class="float--left margin30px left600">Supplément jeune(s) conducteur(s)</span><span class="float--right margin30px right600">0,00&euro;</span></p>
        <p class="clearBoth"></p>
    </div>
  </div>
  <div class="textAlignCenter">
    <v-btn class="text-none text-subtitle-1"
      color="#68AF4D"
      rounded="xl"
      width="250"
      @click="onPageConnectionInscription"
    >
      Continuez votre réservation
    </v-btn>
  </div>
</template>
