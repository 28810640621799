<script>
import { mapState, mapActions } from 'vuex'
import SvgIcon from '@jamescoyle/vue-icon'
import { mdiCarBattery, mdiCarDoor, mdiCircle } from '@mdi/js'
import 'leaflet/dist/leaflet.css'
import { LMap, LTileLayer, LMarker } from '@vue-leaflet/vue-leaflet'
import { Icon } from 'leaflet'
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})
export default {
  components: {
    SvgIcon,
    LMap,
    LMarker,
    LTileLayer
  },
  data() {
    return {
      path: { mdiCarBattery, mdiCarDoor, mdiCircle },
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 100,
      center: [47.666622, -2.786277],
      markerLatLng: [47.666622, -2.786277],
      vehiculeImage: null
    }
  },
  mounted() {

  },
  computed: {
    ...mapState(['lieuDepartRetour', 'dateHeureDepart', 'dateHeureRetour', 'typeLocation', 'isAgePlus25ans', 'siteFleetListeVehicules', 'indexVehiculeChoisi', 'objSite'])
  },
  methods: {
    ...mapActions(['updateIndexVehiculeChoisi', 'updateVehicule']),
    setIndexVehiculeChoisi(index) {
      this.updateIndexVehiculeChoisi(index)
      this.updateVehiculeId(this.siteFleetListeVehicules[index]._id)
      this.updateVehicule(this.siteFleetListeVehicules[index])
    },
    onPageDetailsAndOptions() {
      this.$router.push({ name: 'detailsEtOptions' })
    }
  }
}
</script>

<template>
  <div class="gammeVehiculesLocation">
    <h1>Récapitulatif de votre réservation</h1>
    <p>L'offre VIZION CONNECT c'est une large gamme de véhicules qui vous donne la possibilité de choisir le<br />
      véhicule le plus adapté à votre budget, vos besoins et vos envies.<br />
      Vous cherchez à louer un véhicule utilitaire ou un véhicule de tourisme, choisissez la voiture qui correspond à
      votre besoin.
    </p>
  </div>
  <div>
    <v-divider :thickness="10" color="#68AF4D" class="border-opacity-30"></v-divider>
  </div>
  <!-- Partie gauche : le véhicule sélectionné -->
  <div class="contenuVehiculeChoisi floatLeft marginVehiculeDetail heightVehicleCard">
    <div class="floatLeft largeurVehicule borderStyle heightVehicleCard">
      <h1>{{ siteFleetListeVehicules[indexVehiculeChoisi].model.brand }} {{
      siteFleetListeVehicules[indexVehiculeChoisi].model.title }}</h1>
      <v-img v-if="siteFleetListeVehicules[indexVehiculeChoisi].image"
        :src="'/car-sharing/api/v1/vehicles/' + siteFleetListeVehicules[indexVehiculeChoisi]._id + '/image'"
        style="width:100%;" />
      <div v-if="siteFleetListeVehicules[indexVehiculeChoisi].model.brand != 'S01'" class="floatLeftIcon">
        <div class="floatLeftIcon marginRight10px">
          <svg-icon type="mdi" :path="path.mdiCarBattery"></svg-icon>
        </div>
        <div class="floatLeftIcon">
          {{ siteFleetListeVehicules[indexVehiculeChoisi].model.batteryCapacity }} kw/h
        </div>
      </div>
      <div v-else>
        <div class="floatLeftIcon marginRight10px">
          <svg-icon type="mdi" :path="path.mdiCarBattery"></svg-icon>
        </div>
        <div class="floatLeftIcon marginTop4px">
          {{ siteFleetListeVehicules[indexVehiculeChoisi].model.batteryCapacity }} kw/h
        </div>
      </div>
      <div v-if="siteFleetListeVehicules[indexVehiculeChoisi].model.brand != 'S01'" class="marginRight50px floatRightIcon">
        <div class="floatLeftIcon marginRight10px">
          <svg-icon type="mdi" :path="path.mdiCarDoor"></svg-icon>
        </div>
        <div class="floatLeftIcon marginRight50px marginTop4px">
          {{ siteFleetListeVehicules[indexVehiculeChoisi].model.nbPortes }} portes
        </div>
      </div>
      <div style="clear: left"></div>
      <div>
        <div class="floatLeft marginLeft10px">
          <img src="../assets/cercle-vert.png" />
        </div>
        <div class="floatLeft paddingBottom10px margin40">
          Disponible en agence
        </div>
        <div class="floatLeft paddingBottom10px">
          <p class="pVehicleCard">À partir de {{
      siteFleetListeVehicules[indexVehiculeChoisi].extras != null ?
        siteFleetListeVehicules[indexVehiculeChoisi].extras.prixLocation : '' }}€/J</p>
        </div>
        <div class="clear--both"></div>
      </div>
      <div style="clear: left"></div>
    </div>
  </div>
  <!-- fin Partie gauche : le véhicule sélectionné -->
  <!-- Partie droite : Récapitulatif -->
  <div class="recapitulatif floatLeft marginVehicule height400px">
    <div class="fond--noir">
      <h2 class="padding50 margin--left--30">Récapitulatif</h2>
    </div>
    <div>
      <p class="bold paddingTop20 margin--left--30">Lieu de départ et de retour</p>
      <p class="margin--left--30">{{ lieuDepartRetour }}</p>
    </div>
    <div>
      <div class="floatLeftDate">
        <p class="bold paddingTop20 margin--left--30">Date et heure de départ</p>
        <p class="margin--left--30">{{ dateHeureDepart }}</p>
      </div>
      <div class="floatRightDate margin--right--30">
        <p class="bold paddingTop20 margLeft600">Date et heure de retour</p>
        <p class="margLeft600">{{ dateHeureRetour }}</p>
      </div>
      <div class="clear--both"></div>
      <!-- Séparateur -->
      <div>
        <v-divider :thickness="10" color="#68AF4D" class="border-opacity-30"></v-divider>
      </div>
      <!-- Carte géolocalisation -->
      <div class="conteneurMap">
        <LMap
          v-if="siteFleetListeVehicules[indexVehiculeChoisi].tracking && siteFleetListeVehicules[indexVehiculeChoisi].tracking.gps"
          style="height: 350px; width: 99%" :zoom="zoom"
          :center="[siteFleetListeVehicules[indexVehiculeChoisi].tracking.gps.lat, siteFleetListeVehicules[indexVehiculeChoisi].tracking.gps.lon]">
          <LMarker
            :lat-lng="[siteFleetListeVehicules[indexVehiculeChoisi].tracking.gps.lat, siteFleetListeVehicules[indexVehiculeChoisi].tracking.gps.lon]">
          </LMarker>
          <LTileLayer :url="url" :attribution="attribution"></LTileLayer>
        </LMap>
        <LMap v-else style="height: 350px; width: 99%" :zoom="zoom" :center="center">
          <LMarker :lat-lng="markerLatLng"></LMarker>
          <LTileLayer :url="url" :attribution="attribution"></LTileLayer>
        </LMap>
      </div>
    </div>
  </div>
  <!-- fin Partie droite : Récapitulatif -->
  <div class="clear--left">
  </div>
  <div class="textAlignCenter">
    <v-btn class="text-none text-subtitle-1" color="#68AF4D" rounded="xl" width="250"
      @click.prevent="onPageDetailsAndOptions">
      Continuez votre réservation
    </v-btn>
  </div>
</template>
