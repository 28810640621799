import 'vuetify/styles'
import { createApp } from 'vue'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import app from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
// const proxy = require('node-global-proxy').default

// proxy.setConfig({
//   headers: {
//     'x-site': {
//       _id: '1-wXbT3y4NXq_f8CUai5s'
//     }
//   }
// })
// proxy.start()

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi
    }
  }
})

createApp(app).use(vuetify).use(store).use(router).use(VueAxios, axios).mount('#app')
