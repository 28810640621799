<script>
import { mapState, mapActions } from 'vuex'
import 'leaflet/dist/leaflet.css'
import { LMap, LTileLayer, LMarker } from '@vue-leaflet/vue-leaflet'
export default {
  name: 'ReservationView',
  components: {
    LMap,
    LMarker,
    LTileLayer
  },
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      markerLatLng: [46.902850, 2.750829],
      center: [46.902850, 2.750829],
      attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      searchText: '',
      searchOn: false,
      agenceSelect: false
    }
  },
  computed: {
    ...mapState(['lieuDepartRetour',
      'dateHeureDepart',
      'dateHeureRetour',
      'typeLocation',
      'isAgePlus25ans',
      'siteFleetListeVehicules',
      'indexVehiculeChoisi',
      'owner',
      'siteFleetName',
      'vehicule',
      'moyenPaiement',
      'numeroCartePaiement',
      'nomCartePaiement',
      'dateExpirattionCartePaiement',
      'moisExpirationCartePaiement',
      'anneeExpirationCartePaiement',
      'numeroCVV',
      'isRemplisChampsCartePaiement',
      'titre',
      'prenom',
      'nomFamille',
      'email',
      'telephone',
      'societe',
      'ligneAdresse1',
      'ligneAdresse2',
      'ville',
      'codePostal',
      'isIncription',
      'isFinalisation',
      'listeAgences'])
  },
  created () {
    this._FnGetSites()
  },
  methods: {
    ...mapActions(['updateLieuDepartRetour',
      'updateDateHeureDepart',
      'updateDateHeureRetour',
      'updateTypeLocation',
      'updateIsAgePlus25ans',
      'updateIsInscription',
      'updateIsFinalisation',
      'updateNumeroCartePaiement',
      'updateNomCartePaiement',
      'updateDateExpirationCartePaiement',
      'updateMoisExpirationCartePaiement',
      'updateAnneeExpirationCartePaiement',
      'updateNumeroCVV',
      'updateListeAgences',
      'updateIsRemplisChampsCartePaiement']),
    searchCompany() {
      this.searchOn = true
    },
    async _FnListeAgences () {
      await this.axios.get('/car-sharing/api/v1/agences').then(response => {
        const agences = response.data.results
        this.updateListeAgences(agences)
      })
    },
    _FnGetSites () {
      this._FnListeAgences()
    },
    choisirAgence (agence) {
      this.center = [agence.gps.lat, agence.gps.lng]
      this.markerLatLng  = [agence.gps.lat, agence.gps.lng]
      this.agenceSelect = true
    },
    resetSearch () {
      this.searchOn = false
      this.searchText = ''
      this.agenceSelect = false
    }
  }
}
</script>

<template>
  <div class="gammeVehiculesLocation">
    <h1 class="h1-25">Trouver une agence</h1>
  </div>
  <v-card class="mx-auto ma-2 pa-4" elevation="8" width="100%" rounded="lg">
    <v-text-field class="searchTextField" v-model="searchText" label="Rechercher une agence" append-inner-icon="mdi-magnify" variant="solo" single-line @click:append-inner="searchCompany"></v-text-field>
    <v-btn class="btnResetSearch" @click="resetSearch">Reinitialiser la recherche</v-btn>
  </v-card>
  <v-card class="pa-4 listCompany" elevation="8" rounded="lg">
    <v-list>
      <v-list-item v-for="(agence,index) in listeAgences" :key="agence" min-height="0">
        <v-card v-if="!searchOn || agence.ville.toLowerCase().includes(searchText.toLowerCase())" class="companyCard" rounded="lg" elevation="2" @click="choisirAgence(agence)">
          <p class="companyCity">{{ agence.ville }}</p>
          <p class="companyAddress">Adresse : {{ agence.adresseLigne1 }} {{ agence.adresseLigne2 }}</p>
          <p class="companyCP">Code Postal : {{ agence.codePostal }}</p> 
        </v-card>
      </v-list-item>
    </v-list>
  </v-card>
  <div class="conteneurMapSearchCompany">
    <LMap v-if="!agenceSelect" style="height: 350px; width: 100%" :zoom="5" :center="center">
      <div v-for="(agence,index) in listeAgences" :key="agence">
        <LMarker :lat-lng="[agence.gps.lat, agence.gps.lng]"></LMarker>
      </div>
      <LTileLayer :url="url" :attribution="attribution"></LTileLayer>
    </LMap>
    <LMap v-else style="height: 350px; width: 100%" :zoom="100" :center="center">
      <LMarker :lat-lng="markerLatLng"></LMarker>
      <LTileLayer :url="url" :attribution="attribution"></LTileLayer>
    </LMap>
  </div>
</template>