import { createRouter, createWebHashHistory } from 'vue-router'
import ReservationView from '../components/Reservation.vue'
import ListeVehiculesTrouvesView from '../components/VehiculesTrouves.vue'
import VehiculeChoisiView from '../components/VehiculeChoisi.vue'
import DetailsEtOptionsView from '../components/DetailsEtOptions.vue'
import MoyensPaiementsView from '../components/MoyensPaiements.vue'
import ConnectionInscriptionView from '../components/ConnectionInscription.vue'
import FinalisationView from '../components/FinalisationReservation.vue'
import TrouverAgenceView from '../components/TrouverAgence.vue'

const routes = [
  {
    path: '/',
    name: 'reservation',
    component: ReservationView
  },
  {
    path: '/listeVehicules',
    name: 'listeVehicules',
    component: ListeVehiculesTrouvesView
  },
  {
    path: '/vehiculeChoisi',
    name: 'vehiculeChoisi',
    component: VehiculeChoisiView
  },
  {
    path: '/detailsEtOptions',
    name: 'detailsEtOptions',
    component: DetailsEtOptionsView
  },
  {
    path: '/moyensPaiements',
    name: 'moyensPaiements',
    component: MoyensPaiementsView
  },
  {
    path: '/connectionInscription',
    name: 'connectionInscription',
    component: ConnectionInscriptionView
  },
  {
    path: '/finalisation',
    name: 'finalisation',
    component: FinalisationView
  },
  {
    path: '/trouver-une-agence',
    name: 'trouver-une-agence',
    component: TrouverAgenceView
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.name = 'Vizion Connect Router'
export default router
