<script>
import { mapState, mapActions } from 'vuex'
export default {
  data () {
  },
  mounted () {
  },
  computed: {
    ...mapState(['moyenPaiement',
      'isModePaiement'])
  },
  methods: {
    ...mapActions(['updateMoyenPaiement',
      'updateIsModePaiement']),
    onPaiementAgenceOpenDialog () {
      this.updateIsModePaiement(true)
      this.updateMoyenPaiement('Paiement en agence')
      this.$router.push({ name: 'connectionInscription' })
    },
    onPaiementLigneOpenDialog () {
      this.updateIsModePaiement(true)
      this.updateMoyenPaiement('Paiement en ligne')
      this.$router.push({ name: 'connectionInscription' })
    }
  }
}
</script>

<template>
  <!-- Partie gauche : paiement en agence-->
  <div>
    <h1>Moyens de paiement</h1>
    <div class="width46pc">
      <v-card
        class="cardPay"
        elevation="8"
        width="100%"
        rounded="lg"
      >
        <div class="reservationTimetable" @click="onPaiementAgenceOpenDialog">
          <p class="reservationPay">Payer ma réservation en agence</p>
          <p class="timetable">
            Les horaires de nos agences :<br />08h00 - 12h30<br />13h30 - 19h30
          </p>
        </div>
        <div class="textAlignCenter">
          <v-btn class="text-none text-subtitle-1"
            color="#68AF4D"
            rounded="xl"
            width="250"
            @click="onPaiementAgenceOpenDialog"
          >
            Continuez votre réservation
          </v-btn>
        </div>
      </v-card>
    </div>
  </div>
</template>
